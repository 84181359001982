import React from 'react';
import classNames from 'classnames';

import css from './IconFavoriteFilled.module.css';

/**
 * Description placeholder
 *
 * @component
 * @param {Object} props
 * @param {string?} props.rootClassName
 * @param {string?} props.className
 * @returns {JSX.Element}
 */
const IconFavoriteFilled = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
    >
      <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
    </svg>
  );
};

export default IconFavoriteFilled;
