import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { Modal } from '../../components';
import { getHelpButtonContent } from './HelpButtonConfig';

import css from './HelpButton.module.css';

/**
 * Description placeholder
 *
 * @component
 * @param {Object} props
 * @param {string?} props.rootClassName
 * @param {string?} props.className
 * @param {string?} props.buttonClassName
 * @param {string?} props.fieldId
 * @param {boolean} props.withTextVariant
 * @param {boolean} props.isScrollingDisabled
 * @param {Function} props.onManageDisableScrolling
 * @returns {JSX.Element}
 */
const HelpButton = props => {
  const {
    rootClassName,
    className,
    buttonClassName,
    onManageDisableScrolling,
    isScrollingDisabled,
    fieldId,
    withTextVariant,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const content = getHelpButtonContent(fieldId);
  if (!content) return null;

  const { buttonTextId, buttonTextVariantId, imgSrcDesktop, imgSrcMobile } = content;

  const buttonText = intl.formatMessage({
    id: withTextVariant ? buttonTextVariantId : buttonTextId,
  });

  const classes = classNames(rootClassName || css.root, className);
  const buttonClasses = classNames(css.button, buttonClassName);

  return (
    <div className={classes}>
      <button className={buttonClasses} type="button" onClick={() => setIsOpen(true)}>
        {buttonText}
      </button>
      <Modal
        contentClassName={css.modalContent}
        id={`modal.${fieldId}`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        isScrollingDisabled={isScrollingDisabled}
        usePortal
      >
        {imgSrcDesktop ? <img className={css.imgDesktop} src={imgSrcDesktop} /> : null}
        {imgSrcMobile ? <img className={css.imgMobile} src={imgSrcMobile} /> : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HelpButton);
