import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { SecondaryButton } from '../../components';

import css from './FavoriteButton.module.css';
import IconFavoriteFilled from './Icons/IconFavoriteFilled/IconFavoriteFilled';
import IconFavorite from './Icons/IconFavorite/IconFavorite';

/**
 * Favorite Button
 *
 * @component
 *
 * @param {Object} props
 * @param {string?} props.className add more style rules in addition to components own css.root
 * @param {string?} props.rootClassName overwrite components own css.root
 * @param {Object?} props.currentUser
 * @param {Function} props.onUpdateFavorites
 * @param {string} props.listingId
 * @param {boolean} props.isCard
 *
 * @returns {JSX.Element}
 */
const FavoriteButton = props => {
  const { rootClassName, className, currentUser, onUpdateFavorites, listingId, isCard } = props;
  const history = useHistory();
  const location = useLocation();
  const routes = useRouteConfiguration();
  const intl = useIntl();

  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(listingId);

  const handleToggleFavorites = e => {
    e.preventDefault();

    // Only allow signed-in users to save favorites
    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // Sign up and return back to the listing page.
      history.push(createResourceLocatorString('SignupPage', routes, {}, {}), state);
    } else {
      const {
        attributes: { profile },
      } = currentUser;
      const { favorites = [] } = profile.privateData || {};

      let payload;

      if (!profile.privateData || !profile.privateData?.favorites) {
        payload = {
          privateData: {
            favorites: [listingId],
          },
        };
      } else if (isFavorite) {
        payload = {
          privateData: {
            favorites: favorites.filter(f => f !== listingId),
          },
        };
      } else {
        payload = {
          privateData: {
            favorites: [...favorites, listingId],
          },
        };
      }
      onUpdateFavorites(payload);
    }
  };

  const classes = classNames(isCard ? css.rootCard : css.root, className);
  const iconClasses = classNames(isCard ? css.iconCard : css.icon);

  return (
    <button className={classes} onClick={handleToggleFavorites}>
      {isFavorite ? (
        <IconFavoriteFilled className={iconClasses} />
      ) : (
        <IconFavorite className={iconClasses} />
      )}
      {!isCard ? (
        <span className={css.text}>
          {intl.formatMessage({
            id: `FavoriteButton.${isFavorite ? 'removeFavorite' : 'addFavorite'}`,
          })}
        </span>
      ) : null}
    </button>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateFavorites: payload => dispatch(updateProfile(payload)), // add this row
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FavoriteButton);
