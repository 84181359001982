import React from 'react';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * A component that renders the total price as a line item.
 *
 * @component
 * @param {Object} props
 * @param {propTypes.transaction} props.transaction - The transaction to render
 * @param {boolean} props.isProvider - Whether the provider is the one receiving the commission
 * @param {intlShape} props.intl - The intl object
 * @returns {JSX.Element}
 */
const LineItemCustomTotalPriceMaybe = props => {
  const { intl, marketplaceCurrency, transaction, isProvider } = props;
  const { metadata } = transaction?.attributes || {};
  const { manualRefund } = metadata || {};

  const totalLabel = isProvider ? (
    <FormattedMessage id="OrderBreakdown.providerAmountReceived" />
  ) : (
    <FormattedMessage id="OrderBreakdown.customerAmountRefunded" />
  );

  const totalPrice = isProvider
    ? new Money(manualRefund?.providerReceivedAmount, marketplaceCurrency)
    : new Money(manualRefund?.customerRefundAmount, marketplaceCurrency);

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return formattedTotalPrice ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  ) : null;
};

export default LineItemCustomTotalPriceMaybe;
